<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">区域管理</div>
        <el-upload :http-request="fileUpload" class="cardTitleBtn" action="" :show-file-list='false'>
          <el-button type="success">导入区域数据</el-button>
        </el-upload>
        <el-button type="success" class="cardTitleBtn" style="margin-right:5px" @click="customExport('export')">区域信息导出</el-button>
        <el-button type="success" class="cardTitleBtn" @click="customExport('download')" style="margin-right:5px">下载区域导入模板</el-button>
      </el-col>
      <el-col :span="12">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="200px"
        label-position="right"
        size="mini"
      >
        <el-form-item label="省" prop="provinceName">
          <el-select v-model="provinceGuid" @change="provinceChange">
            <el-option v-for="province in provinceList" :key="province.guid" :label="province.name" :value="province.guid">
            </el-option>
          </el-select>
          <el-button v-if="provinceGuid.length" style="margin-left: 20px;" type="primary" @click="addBlock('province')" icon="el-icon-circle-plus-outline"></el-button>
          <el-button v-if="provinceGuid.length" style="margin-left: 20px;" type="warning" @click="editBlock('province')" icon="el-icon-edit-outline"></el-button>
          <el-button v-if="provinceGuid.length" style="margin-left: 20px;" type="danger" @click="delBlock(provinceGuid,provinceName,'province')" icon="el-icon-delete"></el-button>
        </el-form-item>
        <el-form-item label="市" prop="cityName">
          <el-select v-model="cityGuid" @change="getDistrictList">
            <el-option v-for="city in cityList" :key="city.guid" :label="city.name" :value="city.guid">
            </el-option>
          </el-select>
          <el-button v-if="provinceGuid.length" style="margin-left: 20px;" type="primary" @click="addBlock('city')" icon="el-icon-circle-plus-outline"></el-button>
          <el-button v-if="cityGuid.length" style="margin-left: 20px;" type="warning" @click="editBlock('city')" icon="el-icon-edit-outline"></el-button>
          <el-button v-if="cityGuid.length" style="margin-left: 20px;" type="danger" @click="delBlock(cityGuid,cityName,'city')" icon="el-icon-delete"></el-button>
        </el-form-item>
        <el-form-item label="区" prop="districtName">
          <el-select v-model="districtGuid" @change="getStreetList">
            <el-option v-for="district in districtList" :key="district.guid" :label="district.name" :value="district.guid">
            </el-option>
          </el-select>
          <el-button v-if="cityGuid.length" style="margin-left: 20px;" type="primary" @click="addBlock('district')" icon="el-icon-circle-plus-outline"></el-button>
          <el-button v-if="districtGuid.length" style="margin-left: 20px;" type="warning" @click="editBlock('district')" icon="el-icon-edit-outline"></el-button>
          <el-button v-if="districtGuid.length" style="margin-left: 20px;" type="danger" @click="delBlock(districtGuid,districtName,'district')" icon="el-icon-delete"></el-button>
        </el-form-item>
        <el-form-item label="街道" prop="streetName">
          <el-select v-model="streetGuid" @change="getCommitteeList">
            <el-option v-for="street in streetList" :key="street.guid" :label="street.name" :value="street.guid">
            </el-option>
          </el-select>
          <el-button v-if="districtGuid.length" style="margin-left: 20px;" type="primary" @click="addBlock('street')" icon="el-icon-circle-plus-outline"></el-button>
          <el-button v-if="streetGuid.length" style="margin-left: 20px;" type="warning" @click="editBlock('street')" icon="el-icon-edit-outline"></el-button>
          <el-button v-if="streetGuid.length" style="margin-left: 20px;" type="danger" @click="delBlock(streetGuid,streetName,'street')" icon="el-icon-delete"></el-button>
        </el-form-item>
        <el-form-item label="居委会" prop="committeeName">
          <el-select v-model="committeeGuid" @change="getCommuntyList">
            <el-option v-for="committee in committeeList" :key="committee.guid" :label="committee.name" :value="committee.guid">
            </el-option>
          </el-select>
          <el-button v-if="streetGuid.length" style="margin-left: 20px;" type="primary" @click="addBlock('committee')" icon="el-icon-circle-plus-outline"></el-button>
          <el-button v-if="committeeGuid.length" style="margin-left: 20px;" type="warning" @click="editBlock('committee')" icon="el-icon-edit-outline"></el-button>
          <el-button v-if="committeeGuid.length" style="margin-left: 20px;" type="danger" @click="delBlock(committeeGuid,committeeName,'committee')" icon="el-icon-delete"></el-button>
        </el-form-item>
        <el-form-item label="小区" prop="communityName">
          <el-select v-model="communityGuid" @change="communityChange">
            <el-option v-for="community in communityList" :key="community.guid" :label="community.name" :value="community.guid">
            </el-option>
          </el-select>
          <el-button v-if="committeeGuid.length" style="margin-left: 20px;" type="primary" @click="addBlock('community')" icon="el-icon-circle-plus-outline"></el-button>
          <el-button v-if="communityGuid.length" style="margin-left: 20px;" type="warning" @click="editBlock('community')" icon="el-icon-edit-outline"></el-button>
          <el-button v-if="communityGuid.length" style="margin-left: 20px;" type="danger" @click="delBlock(communityGuid,communityName,'community')" icon="el-icon-delete"></el-button>
        </el-form-item>

      </el-form>
      </el-col>
      <el-col :span="12">
        <!-- <el-card class="box-card" shadow="never" body-style="5px"> -->
        <el-form :model="adminList"
          label-width="200px"
          label-position="top"
          size="mini"
        >
        <el-form-item label="备注：">
          <template>
            <p v-html="adminList.remark" style="margin-left: 20px"></p>
          </template>
        </el-form-item>
        <el-form-item label="物业经理姓名：">
            <span style="margin-left: 20px">{{adminList.admin.name}}</span>
        </el-form-item>
        <el-form-item label="物业经理手机号：">
            <span style="margin-left: 20px">{{adminList.admin.phone}}</span>
        </el-form-item>
        </el-form>
        <!-- </el-card> -->
      </el-col>
    </el-row>
    <el-dialog
      :title="'添加' + blockForm.blockName"
      :visible.sync="blockDialogVisible"
      width="50%"
      >
        <el-form
          ref="form"
          :model="blockForm"
          :rules="blockRules"
          label-width="100px"
          label-position="right"
          size="mini"
        >
          <el-form-item :label="blockForm.blockName + '名称'" prop="name">
            <el-input v-model="blockForm.name" :placeholder="blockForm.blockName + '名称'"></el-input>
          </el-form-item>
          <el-form-item v-if="blockForm.blockName == '小区'" label="小区备注">
            <quill-editor v-model="blockForm.remark" :options="editorOption"></quill-editor>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="blockDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doSubmitBlock">确 定</el-button>
        </span>
    </el-dialog>
  </el-container>
</template>

<script>
export default({
  data() {
    return {
      provinceList: [],
      cityList: [],
      districtList: [],
      streetList: [],
      committeeList: [],
      communityList: [],
      provinceName: "",
      cityName: "",
      districtName: "",
      streetName: "",
      committeeName: "",
      communityName: "",
      provinceGuid: "",
      cityGuid: "",
      districtGuid: "",
      streetGuid: "",
      committeeGuid: "",
      communityGuid: "",
      dialogVisible: false,
      lookUp: ["province", "city", "district", "street", "committee", "community"],
      enToCn: {
        province: "省", city: "市", district: "区", street: "街道", committee: "居委会", community: "小区"
      },
      form: {
        provinceGuid: "",
        cityGuid: "",
        districtGuid: "",
        streetGuid: "",
        committeeGuid: "",
        communityGuid: ""
      },
      blockForm: {
        blockName: "",
        blockNameEn: "",
        name: "",
        guid: "",
        remark:""
        // superiorGuid: "",
      },
      blockRules: {
        name: [{required: true, message: "名称不能为空", trigger: "blur"}]
      },
      blockDialogVisible: false,
      rules: {},
      editorOption: {
        placeholder: '请输入正文......',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [
              { 'list': 'ordered'},
              { 'list': 'bullet'}
            ],
            [
              { 'size': ['small', false, 'large', 'huge'] }
            ],
            [
              { 'header': [1, 2, 3, 4, 5, 6, false] }
            ],
            [
              { 'color': [] }
            ],
            [
              { 'align': [] }
            ]
          ]
        }
      },
      adminList: {
        admin: {},
        remark: ''
      }
    }
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getProvinceList();
    // self['getProvinceList']();
  },
  methods: {
    getProvinceList() {
      let self = this;
      self.$axios.get("/province/list", {
        headers: { token: self.userInfo.token },
      }).then(function(response) {
        self.provinceList = response.data.data;
        self.loopRun("province");
        console.log("self.provinceList = ", self.provinceList);
      }).catch(function (error) {
        self.$message({
          message: "省列表获取失败：" + error.response.data.message,
          type: "warning",
        });
      });
    },
    createProvince() {
      this.$router.push({ name: "ProvinceDetail" });
    },
    getDetail(row) {
      let self = this;
      row;
      self.dialogVisible = true;
      // self.$router.push({ name: "ProvinceDetail", query: { guid: row.guid } });
    },
    loopRun(element) {
      console.log("in loopRun");
      let self = this;
      let _index = self.lookUp.indexOf(element);
      console.log("_index = ", _index);
      let elementArray = self.lookUp.slice(_index);
      console.log("elementArray = ", elementArray);
      // for (let i = 0; i < self.lookUp.length; i++) {
      //   console.log(self.lookUp[i] + "Guid = " + self[self.lookUp[i]+"Guid"] );
      // }
      console.log("======");
      for (let i = 0; i < elementArray.length; i++) {
        console.log(elementArray[i] + "Guid = ", self[elementArray[i] + "Guid"]);
        self[elementArray[i] + "Guid"] = "";
        console.log(elementArray[i] + "Guid = ", self[elementArray[i] + "Guid"]);
      }
    },
    provinceChange() {
      let self = this;
      console.log("provinceChange");
      console.log("self.provinceGuid = ", self.provinceGuid);
      self.getCityList(self.provinceGuid);
      self.provinceName = self.$underscore.find(self.provinceList, function(_el) {
        return _el.guid == self.provinceGuid;
      }).name;
      // self.loopRun("province");
    },
    getCityList() {
      let self = this;
      self.$axios.get("/city/list?provinceGuid=" + self.provinceGuid, {
        headers: { token: self.userInfo.token },
      }).then(function(response) {
        self.cityList = response.data.data;
        self.districtList= "",
        self.streetList= "",
        self.committeeList= "",
        self.communityList= "",
        console.log("self.provinceList = ", self.cityList);
        self.loopRun("city");
      }).catch(function (error) {
        self.$message({
          message: "城市列表获取失败：" + error.response.data.message,
          type: "warning",
        });
      });
    },
    getDistrictList() {
      let self = this;
      self.$axios
        .get("/district/list?cityGuid=" + self.cityGuid, {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.cityName = self.$underscore.find(self.cityList, function(_el) {
            return _el.guid == self.cityGuid;
          }).name;
          self.districtList = response.data.data;
          self.streetList= "",
          self.committeeList= "",
          self.communityList= "",
          console.log("self.districtList = ", self.districtList);
          self.loopRun("district");
        })
        .catch(function (error) {
          self.$message({
            message: "区列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    getStreetList() {
      let self = this;
      self.$axios
        .get("/street/list?districtGuid=" + self.districtGuid, {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.streetList = response.data.data;
          self.committeeList= "",
          self.communityList= "",
          self.districtName = self.$underscore.find(self.districtList, function(_el) {
            return _el.guid == self.districtGuid;
          }).name;
          self.loopRun("street");
        })
        .catch(function (error) {
          self.$message({
            message: "街道列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    getCommitteeList() {
      let self = this;
      self.$axios
        .get("/committee/list?streetGuid=" + self.streetGuid, {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.committeeList = response.data.data;
          self.communityList= "",
          self.streetName = self.$underscore.find(self.streetList, function(_el) {
            return _el.guid == self.streetGuid;
          }).name;
          self.loopRun("committee");
        })
        .catch(function (error) {
          self.$message({
            message: "居委会列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    getCommuntyList() {
      let self = this;
      self.$axios
        .get("/community/list?committeeGuid=" + self.committeeGuid, {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.communityList = response.data.data;
          self.committeeName = self.$underscore.find(self.committeeList, function(_el) {
            return _el.guid == self.committeeGuid;
          }).name;
          self.loopRun("community");
        })
        .catch(function (error) {
          self.$message({
            message: "社区列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    communityChange() {
      let self = this;
      console.log("self.communityList = ", self.communityList);
      console.log("self.communityName = ", self.communityName);
      self.communityName = self.$underscore.find(self.communityList, function(_el) {
        return _el.guid == self.communityGuid;
      }).name;
      self.getAdminList(self.communityGuid)
    },
    getAdminList(guid) {
      let self = this
      console.log(self.communityList);
      console.log(guid);
      self.communityList.forEach(item => {
        if(guid == item.guid) {
          self.adminList.remark = item.remark
          self.adminList.admin = item.admin
        }
      })
      console.log(self.adminList);
    },
    addBlock(block) {
      let self = this;
      console.log("block = ", block);
      self.blockForm.blockNameEn = block;
      self.blockForm.blockName = self.enToCn[block];
      self.blockForm.name = "";
      self.blockForm.guid = "";
      self.blockForm.remark = "";
      self.blockDialogVisible = true;
    },
    editBlock(block) {
      let self = this;
      console.log("block = ", block);
      self.blockForm.blockNameEn = block;
      self.blockForm.blockName = self.enToCn[block];
      self.blockForm.guid = self[block+"Guid"];
      self.blockForm.name = self.$underscore.find(self[block+"List"], function(_el) {
        return _el.guid == self.blockForm.guid;
      }).name;
      self.blockForm.remark = self.$underscore.find(self[block+"List"], function(_el) {
        return _el.guid == self.blockForm.guid;
      }).remark;
      self.blockDialogVisible = true;
    },
    superiorBlock() {
      let self = this;
      let _index = self.lookUp.indexOf(self.blockForm.blockNameEn) - 1;
      let superiorBlock = self.lookUp[_index];
      return superiorBlock;
    },
    doSubmitBlock() {
      let self = this;
      console.log("current block = ", self.blockForm.blockNameEn);
      // sendAddBlockRequest()
      let superiorBlockGuidName = self.superiorBlock() + "Guid";
      let data = {
        guid: self.blockForm.guid,
        name: self.blockForm.name,
        remark: self.blockForm.remark,
        [superiorBlockGuidName]: self[superiorBlockGuidName],
      };
      // if (self.blockForm.guid) {
      //   data.guid = self.blockForm.guid;
      // }
      console.log("data = ", data);
      self.$axios
        .post("/" + self.blockForm.blockNameEn + "/saveOrUpdate", data, {
          headers: {
            token: self.userInfo.token
          },
        }).then(function(response) {
          console.log("response.data.data = ", response.data.data);
          self.$message({
            message: "操作成功",
            type: "success",
          });
          if (self.blockForm.guid) {
            // 修改
            self.$underscore.each(self[self.blockForm.blockNameEn+"List"], function(_el, _index) {
              if (_el.guid == self.blockForm.guid) {
                // self[self.blockForm.blockNameEn+"List"][_index] = response.data.data;
                self.$set(self[self.blockForm.blockNameEn+"List"], _index, response.data.data)
              }
            })
          } else {
            // 添加
            self[self.blockForm.blockNameEn+"List"].push(response.data.data)
          }
          location.reload();
          self.blockDialogVisible = false;
        }).catch(function (error) {
          self.$message({
            message: error.response.data.message,
            type: "warning",
          });
        });
    },
    // 数据导出或下载
    customExport(type) {
      let url
      if(type == 'export') {
        url = this.GLOBAL.host + "/community/export"
      } else if (type == 'download') {
        url = this.GLOBAL.host + "/community/template/download"
      }
      var a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.click();
    },
    // 导入数据
    fileUpload(files) {
      let self = this;
      let file = new FormData()
      file.append('file', files.file)
      console.log(file);
      self.$axios
        .post("/community/import", file ,{
          headers: { token: self.userInfo.token,'Content-Type':'multipart/form-data'}
        })
        .then(function(res) {
          self.$message({
            message:res.data.message,
            type: "success",
          });
        })
        .catch(function (error) {
          self.$message({
            message:error.response.data.message,
            type: "warning",
          });
        });
    },
    // 删除小区
    delBlock(guid,name,block) {
      console.log(guid);
      console.log(name);
      let self = this;
      self.$confirm("确定删除" + name + "吗？")
      .then(() => {
          self.$axios
          .delete("/"+block+"/delete", {
              data: { guid: guid },
              headers: { token: self.userInfo.token },
          })
          .then(function () {
              self.$message({
              message: "删除成功",
              type: "success",
              });
              location.reload();
          })
          .catch(function (error) {
              self.$message({
              message: "删除失败" + error.response.data.message,
              type: "warning",
              });
          });
      })
      .catch(() => {});
    }
  }
})
</script>
